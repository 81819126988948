<template>
  <ui-component-modal
    :modalTitle="$t('Components.Events.EventOrganizer.Edit.Main_Title')"
    :disableSaveButton="disableSaveButton"
    :onClickCancel="onClickCancel"
    :onClickSave="uploadAndSave"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth">
        <tbody>
          <tr>
            <td>
              {{ $t('Components.Events.EventOrganizer.Add.Label_Name') }}
            </td>
            <td>
              <ui-base-input-field
                v-model="mOrganizer.Name"
                :error="nameErrors"
                :required="true"
                :showLabel="false"
                fieldType="text"
                @input="$v.mOrganizer.Name.$touch()"
                @blur="$v.mOrganizer.Name.$touch()"
              />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Components.Events.EventOrganizer.Add.Label_Email') }}
            </td>
            <td>
              <ui-base-input-field
                v-model="mOrganizer.Email"
                :error="emailErrors"
                :required="true"
                :showLabel="false"
                fieldType="text"
                @input="$v.mOrganizer.Email.$touch()"
                @blur="$v.mOrganizer.Email.$touch()"
              />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Components.Events.EventOrganizer.Add.Label_Phone') }}
            </td>
            <td>
              <input
                type="text"
                v-model="mOrganizer.Phone"
                class="input"
              />
            </td>
          </tr>

          <tr>
            <td>
              {{
                $t(
                  'Components.Events.EventOrganizer.Edit.Label_ShowAsOrganizer'
                )
              }}
            </td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': mOrganizer.IsPrimary }">
                    <a @click="setIsPrimary(true)">
                      <span>{{ $t(`General.Yes`) }}</span>
                    </a>
                  </li>

                  <li :class="{ 'is-active': !mOrganizer.IsPrimary }">
                    <a @click="setIsPrimary(false)">
                      <span>{{ $t(`General.No`) }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              {{
                $t('Components.Events.EventOrganizer.Edit.Label_ReceiveCheckin')
              }}
            </td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': mOrganizer.ReceiveCheckinMail }">
                    <a @click="mOrganizer.ReceiveCheckinMail = true">
                      <span>{{ $t(`General.Yes`) }}</span>
                    </a>
                  </li>

                  <li :class="{
                      'is-active': !mOrganizer.ReceiveCheckinMail,
                    }">
                    <a @click="mOrganizer.ReceiveCheckinMail = false">
                      <span>{{ $t(`General.No`) }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              {{ $t('Components.Events.EventOrganizer.Edit.Label_Image') }}
            </td>
            <td>
              <div class="imageContainer">
                <ImageCropper
                  ref="imageCropper"
                  :aspectRatio="1"
                  :viewMode="3"
                  :guides="false"
                  aspectRatioCssClass="is-1by1"
                  imageContainerCssClass="is-rounded"
                  uploadButtonView="icon-text"
                  :isStatic="isSystemProfile"
                  :originalImage="$options.filters.getEventImage(mOrganizer.Image, '240')"
                  @callbackImageChosen="val => { newImageChosen = val }"
                  @errorCallbackInvalid="val => { hasImageError = val }"
                  class="organizerImage"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import eventProvider from '@/providers/event'
import uploadProvider from '@/providers/upload'

const ImageCropper = () => import('@/components/UI/Components/ImageCropper')

import { required, minLength, email } from 'vuelidate/lib/validators'
const touchMap = new WeakMap()

export default {
  components: {
    ImageCropper,
  },

  props: {
    organizer: {
      type: Object,
      default: null,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      newImageChosen: false,
      hasImageError: false,
      mEvent: null,
      mOrganizer: JSON.parse(JSON.stringify(this.organizer)),
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      Name: {
        required,
        minLength: minLength(2),
      },
      Email: {
        required,
        email,
      },
    }
    return {
      mOrganizer: output,
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData']),

    disableSaveButton() {
      return this.isSaving || this.hasImageError || this.nameErrors.length || this.emailErrors.length
    },

    nameErrors() {
      const errors = []
      if (!this.$v.mOrganizer.Name.$dirty) return errors
      !this.$v.mOrganizer.Name.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.mOrganizer.Name.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 2 }))
      return errors
    },

    emailErrors() {
      const errors = []
      if (!this.$v.mOrganizer.Email.$dirty) return errors
      if (!this.$v.mOrganizer.Email.required) {
        errors.push(this.$t('Form.InputErrors.Required'))
      } else if (!this.$v.mOrganizer.Email.email) {
        errors.push(this.$t('Form.InputErrors.InvalidEmail'))
      }
      return errors
    },
  },

  created() {
    this.mEvent = JSON.parse(JSON.stringify(this.eventData))
  },

  methods: {
    ...mapMutations('eventStore', ['setEventData']),

    setIsPrimary(isPrimary) {
      this.mOrganizer.IsPrimary = isPrimary
    },

    async uploadAndSave() {
      await this.$v.$touch()
      if (this.disableSaveButton) { return }
      this.isSaving = true

      try {
        if (this.newImageChosen) {
          const NEW_IMAGE = this.$refs.imageCropper.getCroppedCanvas()
          let imageUploadResponse = await uploadProvider.methods.eventImage(this.mOrganizer.EventId, NEW_IMAGE)
          if (imageUploadResponse.status === 200) {
            this.mOrganizer.Image = imageUploadResponse.data
          }
          this.saveEvent()
        }
      } catch {
        this.isSaving = false
        this.isSavingError = true
      }
    },

    saveEvent() {
      let self = this
      this.$v.$touch()
      if (this.$v.$invalid) {
        // 'ERROR'
        self.isSaving = false
      } else {
        if (self.mOrganizer.IsPrimary) {
          let oldPrimaryIndex = self.mEvent.Organizers.findIndex(
            (o) => o.IsPrimary
          )
          if (oldPrimaryIndex > -1) {
            let oldPrimaryOrganizer = self.mEvent.Organizers[oldPrimaryIndex]
            oldPrimaryOrganizer.IsPrimary = false

            Vue.set(
              self.mEvent.Organizers,
              oldPrimaryIndex,
              oldPrimaryOrganizer
            )
          }
        }

        let organizerIndex = this.eventData.Organizers.findIndex(
          (o) => o.Id === self.mOrganizer.Id
        )
        if (organizerIndex > -1) {
          Vue.set(self.mEvent.Organizers, organizerIndex, self.mOrganizer)
        }

        eventProvider.methods
          .updateEvent(self.mEvent)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              self.setEventData(response.data)

              let t = setTimeout(() => {
                self.onClickCancel()
                clearTimeout(t)
              }, 1500)
            }
          })
          .catch(() => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  z-index: 102;
}
.table {
  td {
    vertical-align: middle;
    .imageContainer {
      max-width: 240px;
    }
  }
}
</style>
